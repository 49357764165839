<template lang="pug">
Layout
  PageHeader(:title="title")
  .row
    .col-lg-12
      //- 表格
      b-card
        b-card-body
          .header-row.mt-0.mb-3.d-flex.justify-content-end.align-items-start
            b-button.width-md(variant="primary",:to="'/file-edit/create'") ＋ 新增
          //- Table
          .table-responsive.mb-0
            table.table
              thead
                th #
                th 檔案名稱
                th 檔案類型
                th 短網址
                th QRCode
                th 公開起始日
                th 公開結束日
                th 觀看人數
                th 操作
              tbody
                tr(v-for="( file, index ) in files")
                  td {{ index + 1 }}
                  td {{ file.title }}
                  td
                    span(v-if="file.type == 1") 檔案
                    span(v-if="file.type == 2") 連結
                  td.max-width-200 {{ (file.customize_link ? file.customize_link : file.sort_uri) }}
                  td
                    .qrcode_box
                      qr-code(:text='(file.customize_link ? file.customize_link : file.sort_uri)' :size='50' color='#000' bg-color='#fff' error-level='L')
                  td {{ ( file.start_date ) ? file.start_date : '-' }}
                  td {{ ( file.end_date ) ? file.end_date : '-' }}
                  td.center -
                  td
                    b-button.table-btn(variant="primary" :to="'/file-edit/' + file.id")
                      | 編輯
                    //- b-button.ml-2(variant="danger" @click="deleteProgram(row.item.id)")
                      i.fe-trash-2 


          .row.mb-md-2
            .col
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")
</template>

<script>
import Layout from "../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  name: "FileList",
  data () {
    return {
      title: "檔案管理",
      totalRows: 0,
      currentPage: 1,
      perPage: 0,
      filter: null,
      filterOn: [],
      sortDesc: false,
      files: [],
      fields: [
        { key: "index", label: "#", sortable: false, class: "center" },
        { key: "title", label: "檔案名稱", sortable: false, class: "" },
        { key: "type", label: "檔案類型", sortable: false, class: "" },
        { key: "sort_uri", label: "短網址", sortable: false, class: "center" },
        { key: "qrcode", label: "QRCode", sortable: false, class: "center" },
        { key: "start_date", label: "公開起始日", sortable: false, class: "center" },
        { key: "end_date", label: "公開結束日", sortable: false, class: "center" },
        { key: "views", label: "觀看人數", sortable: false, class: "center" },
        { key: "editor", label: "操作", sortable: false, class: "center" }
      ]
    };
  },
  computed: {
  },
  mounted () {
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  created () {
    this.getFiles()
  },
  watch: {
    currentPage (_page) {
      this.getFiles()
    }
  },
  methods: {
    // 獲得節目清單
    async getFiles () {
      let vm = this
      let search = { page: this.currentPage }
      await this.$root.apis.getFileList(search,
        async function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          vm.files = response.items
        })
    },
  }
}
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
.header-row
  display: flex
  justify-content: space-between
  align-items: center
  .header-title
    margin: 0
    flex: 1
.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
.max-width-200
  max-width: 200px
</style>
